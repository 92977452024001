<template>
	<div>
		<el-form
			ref="queryForm"
			class="query-box"
			v-model="queryParams"
			:inline="true"
		>
			<el-form-item
				label="注册时间"
				name="createDateRange"
				label-width="140px"
			>
				<el-date-picker
					v-model="createDateRange"
					size="small"
					value-format="yyyy-MM-dd"
					type="daterange"
					range-separator="-"
					style="width: 240px"
					@change="getDateRange"
					start-placeholder="开始时间"
					end-placeholder="结束时间"
				/>
			</el-form-item>
			<el-form-item
				label="商户编号"
				prop="merchantNo"
				label-width="140px"
			>
				<el-input
					v-model="queryParams.merchantNo"
					clearable
					placeholder="请输入商户编号"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item
				label="上游商户编号"
				prop="outMerchantNo"
				label-width="140px"
			>
				<el-input
					v-model="queryParams.outMerchantNo"
					clearable
					placeholder="请输入商户编号"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item
				label="商户简称"
				prop="lsMerchantName"
				label-width="140px"
			>
				<el-input
					v-model="queryParams.lsMerchantName"
					clearable
					placeholder="请输入商户简称"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item label="代理商编号" prop="agentNo" label-width="140px">
				<el-input
					v-model="queryParams.agentNo"
					clearable
					placeholder="请输入代理商编号"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item label="机构编号" prop="orgNo" label-width="140px">
				<el-input
					v-model="queryParams.orgNo"
					clearable
					placeholder="请输入机构编号"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>

			<el-form-item label="渠道" prop="channelNo" label-width="140px">
				<el-select
					v-model="queryParams.channelNo"
					placeholder="渠道"
					clearable
					size="small"
					style="width: 240px"
				>
					<el-option label="全部" value="" />
					<el-option
						v-for="(item, key) of dicts.CHANNEL"
						:key="key"
						:label="item"
						:value="key"
					/>
				</el-select>
			</el-form-item>
			<el-form-item
				label="激活状态"
				prop="activeStatus"
				label-width="140px"
			>
				<el-select
					v-model="queryParams.activeStatus"
					size="small"
					placeholder="请选择激活状态"
				>
					<el-option label="全部" value=""></el-option>
					<el-option label="未激活" value="0"></el-option>
					<el-option label="已激活" value="1"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button
					type="primary"
					icon="el-icon-search"
					size="mini"
					@click="handleQuery"
					v-permission="'MERCHANT_INFO_QUERY'"
				>
					查询
				</el-button>
				<el-button
					icon="el-icon-refresh"
					size="mini"
					@click="handleResetQuery"
					v-permission="'MERCHANT_INFO_QUERY'"
					>重置</el-button
				>
				<el-button
					icon="el-icon-download"
					size="mini"
					@click="handleExport"
				>
					导出
				</el-button>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { UserApi, BasicApi } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: {}
		}
	},
	data() {
		return {
			queryParams: {},
			// queryParams: { provinceCode: "", cityCode: "" },
			createDateRange: [],
			updateDateRange: [],
			showCustomForm: false,
			operaterUserList: "",
			provinceList: "",
			cityList: ""
		};
	},
	created() {
		UserApi.user.list(1, 2000).then(res => {
			if (res.success) {
				this.operaterUserList = res.data;
			}
		});
		BasicApi.listProvinceCity().then(res => {
			if (res.success) {
				this.provinceList = res.data;
			}
		});
	},
	methods: {
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.commitChange();
		},
		handleQuery() {
			this.commitChange();
		},
		handleResetQuery() {
			this.queryParams = {};
			this.createDateRange = [];
			this.updateDateRange = [];
			this.commitChange();
		},
		//导出功能
		handleExport() {
			this.$confirm("确定导出商户管理吗", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(async () => {
				this.dealWithQueryParams();
				let result = await UserApi.merchantInfo.export(
					this.queryParams
				);
				console.log("export----->207", result);
				if (result.success) {
					this.downloadFile(result.data);
				}
			});
		},
		dealWithQueryParams() {
			if(!this.createDateRange){
				this.createDateRange =[]
			}

			let [startCreateTime, endCreateTime] = this.createDateRange;
			this.queryParams.startCreateTime =
				startCreateTime && startCreateTime + " 00:00:00";
			this.queryParams.endCreateTime =
				endCreateTime && endCreateTime + " 23:59:59";

			let [startUpdateTime, endUpdateTime] = this.updateDateRange;
			this.queryParams.startUpdateTime =
				startUpdateTime && startUpdateTime + " 00:00:00";
			this.queryParams.endUpdateTime =
				endUpdateTime && endUpdateTime + " 23:59:59";
		},
		commitChange() {
			this.dealWithQueryParams();
			let params = { ...this.queryParams };
			this.$emit("on-change", params);
		},
		provinceChange(val) {
			this.queryParams.cityCode = "";
			this.provinceList.map(e => {
				if (val == e.code) {
					this.cityList = e.children;
				}
			});
		},
		getDateRange(e) {
			if(e){
				this.queryParams.beginMerCreateTime = e[0];
				this.queryParams.endMerCreateTime = e[1];
			}else{
				this.queryParams.beginMerCreateTime = "";
				this.queryParams.endMerCreateTime = "";
			}
		}
	}
};
</script>

<style scoped></style>
